import Layout from "../components/Layout"
import React from "react"
import Seo from "../components/seo"

const AboutPage = () => {
  return (
    <Layout >
      <Seo title="About" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading md:text-center">About</h1>
        
        <section className="max-w-prose mx-auto mt-10 lg:mt-20 ele-style lg:text-xl">
          <p>I'm a die-hard Talman enthusiast. I've been researching and collecting these guitars for a long time. I fully believe they are the best kept secret in the guitar world.</p>
          <p>I intend for this website to be "the place" to go for Talman info. Over the years I've acquired a lot of knowledge and feel the need to share it with the world. (I also just want other people to nerd out over Talmans with me!)</p>
          <p>If you've never played a Talman I hope I can convince you to pick one up.</p>
          <p>- Tal</p>

          <div className="mt-20 text-sm">
            <h2 id="approach-to-talman-data-specs">My approach to Talman data/specs</h2>
            <p>I cross-reference original catalogs, price lists, magazine articles, books and other websites to double check all specs and Talman related info, but I've assumed nothing is true until I verify with real guitars in hand. If what I directly measure or witness conflicts with other sources, I assume my examination is more reliable. If I later discover any data is inaccurate I will update and correct it. My goal is for this website to be the authority on Talmans by having the most accurate, factual details.</p>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export default AboutPage